<template>
  <div>
    <div
      @click="isOpened = !isOpened"
      :class="[
        'choose-relative',
        'modal-service__bl',
        { 'choose-relative--active': isOpened}
      ]">
      <div class="modal-service__next">

        <div class="modal-service__next-label">Кому будет оказываеться помощь</div>
        <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'choose-relative-caret'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M12.062.065l1.428 1.426-6.494 6.507L.49 1.49 1.917.064l5.078 5.078L12.062.065z" fill="#AEAEB2"/></svg>

      </div>
    </div>

    <template v-if="isOpened">
      <div
        class="av-radio"
        v-for="(relative, i) in relatives"
        :key="`relative-${i}`"
        @click="selectRelative(i)"
      >
        <div :class="[
          'av-radio__vue',
          { 'av-radio__vue--active': selectedRelativeIndex === i }
        ]">
          <div class="av-radio__block">
            <div class="av-radio__avatar">

              <img
                v-if="relative.photo"
                :src="relative.photo"
                class="av-radio__img"
                alt="avatar"
              >

              <img
                v-else
                :src="require('@img/user-avatar.svg')"
                class="av-radio__img"
                alt="avatar"
              >

            </div>
            <div class="av-radio__cont">

              <div class="av-radio__name">{{ relative.name }}</div>

              <div class="av-radio__role">{{ relative.state }}</div>

            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="selectedRelativeIndex !== null">
      <div
        class="av-radio"
      >
        <div :class="[
          'av-radio__vue',
          { 'av-radio__vue--active': selectedRelativeIndex + 1 > 0 }
        ]">
          <div class="av-radio__block">
            <div class="av-radio__avatar">

              <img
                v-if="selectedRelative.photo"
                :src="selectedRelative.photo"
                class="av-radio__img"
                alt="avatar"
              >

              <img
                v-else
                :src="require('@img/user-avatar.svg')"
                class="av-radio__img"
                alt="avatar"
              >

            </div>
            <div class="av-radio__cont">

              <div class="av-radio__name">{{ selectedRelative.name }}</div>

              <div class="av-radio__role">{{ selectedRelative.state }}</div>

            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: "ModalServiceNeedChooseRelative",
  data() {
    return {
      isOpened: false,
      selectedRelativeIndex: null
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      states: state => state.enums.states
    }),
    family() {
      return this.user.family_set;
    },
    relatives() {
      return this.family.map(relative => {
        const {
          id,
          first_name,
          last_name,
          state,
          photo
        } = relative;

        const stateName = this.states.find(item => item.key === state )?.value;
        return {
          id,
          name: `${ first_name } ${ last_name }`,
          state: stateName,
          photo
        };
      });
    },
    selectedRelative() {
      return this.relatives[this.selectedRelativeIndex];
    }
  },
  methods: {
    selectRelative(i = null) {
      this.selectedRelativeIndex = i;
      if (i !== null) {
        this.$emit('input', this.relatives[i].id);
      }
      this.isOpened = false;
    }
  },
  async mounted() {
    await this.$store.dispatch('enums/getStates');
  }
};
</script>

<style lang="scss" scoped>
  .choose-relative {
    .choose-relative-caret {
      transform: scale(-1, 1);
    }
    &--active .choose-relative-caret {
      transform: scale(-1, -1);
    }
  }

  .av-radio {
    .av-radio__vue--active {
      border: 1px solid var(--color-prime);
    }
  }

</style>
