<template>
  <div>
    <div class="modal-service__head">

      <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'modal-service__back'" @click="$emit('back')" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M12.062.065l1.428 1.426-6.494 6.507L.49 1.49 1.917.064l5.078 5.078L12.062.065z" fill="#AEAEB2"/></svg>
      Регулярность
    </div>
    <div class="modal-service__sub">

      <div class="modal-service__title">Выполнять в указанные дни</div>
      <div class="modal-service__text">Выберите дни, в которые должны предоставляться услуги</div>

      <div class="modal-service__list">
        <template v-for="item in repeats">
          <label class="ch-line" :key="item.key">

            <input
              class="ch-line__field"
              type="checkbox"
              :value="item.key"
              v-model="selectedDayList"
            />

            <div class="ch-line__block">

              <div class="ch-line__label">{{ item.value }}</div>
              <div class="ch-line__box">
                <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M3.021 6.41L8.785.646l.736.762-6.5 6.5L0 4.887l.762-.762 2.26 2.285z" fill="#0C72D6"/></svg>
              </div>

            </div>

          </label>
        </template>
      </div>

      <button
        type="button"
        class="btn btn--prime"
        @click="$emit('back')"
      >Сохранить</button>

    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "ModalServiceNeedRegularity",
  model: {
    event: 'change',
    prop: 'regularity'
  },
  props: {
    regularity: Array
  },
  computed: {
    ...mapState({
      repeats: state => state.enums.repeats
    }),
    selectedDayList: {
      get() {
        return this.regularity;
      },
      set(v) {
        this.$emit('change', v);
      }
    }
  },
  mounted() {
  }
};
</script>

<style lang="scss" scoped>

</style>
