var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{class:[
      'choose-relative',
      'modal-service__bl',
      { 'choose-relative--active': _vm.isOpened}
    ],on:{"click":function($event){_vm.isOpened = !_vm.isOpened}}},[_c('div',{staticClass:"modal-service__next"},[_c('div',{staticClass:"modal-service__next-label"},[_vm._v("Кому будет оказываеться помощь")]),_c('svg',{class:'choose-relative-caret',attrs:{"width":"14","height":"8","viewBox":"0 0 14 8","fill":"none","xmlns":"http://www.w3.org/2000/svg","svg-inline":'',"role":'presentation',"focusable":'false',"tabindex":'-1'}},[_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M12.062.065l1.428 1.426-6.494 6.507L.49 1.49 1.917.064l5.078 5.078L12.062.065z","fill":"#AEAEB2"}})])])]),(_vm.isOpened)?_vm._l((_vm.relatives),function(relative,i){return _c('div',{key:("relative-" + i),staticClass:"av-radio",on:{"click":function($event){return _vm.selectRelative(i)}}},[_c('div',{class:[
        'av-radio__vue',
        { 'av-radio__vue--active': _vm.selectedRelativeIndex === i }
      ]},[_c('div',{staticClass:"av-radio__block"},[_c('div',{staticClass:"av-radio__avatar"},[(relative.photo)?_c('img',{staticClass:"av-radio__img",attrs:{"src":relative.photo,"alt":"avatar"}}):_c('img',{staticClass:"av-radio__img",attrs:{"src":require('@img/user-avatar.svg'),"alt":"avatar"}})]),_c('div',{staticClass:"av-radio__cont"},[_c('div',{staticClass:"av-radio__name"},[_vm._v(_vm._s(relative.name))]),_c('div',{staticClass:"av-radio__role"},[_vm._v(_vm._s(relative.state))])])])])])}):(_vm.selectedRelativeIndex !== null)?[_c('div',{staticClass:"av-radio"},[_c('div',{class:[
        'av-radio__vue',
        { 'av-radio__vue--active': _vm.selectedRelativeIndex + 1 > 0 }
      ]},[_c('div',{staticClass:"av-radio__block"},[_c('div',{staticClass:"av-radio__avatar"},[(_vm.selectedRelative.photo)?_c('img',{staticClass:"av-radio__img",attrs:{"src":_vm.selectedRelative.photo,"alt":"avatar"}}):_c('img',{staticClass:"av-radio__img",attrs:{"src":require('@img/user-avatar.svg'),"alt":"avatar"}})]),_c('div',{staticClass:"av-radio__cont"},[_c('div',{staticClass:"av-radio__name"},[_vm._v(_vm._s(_vm.selectedRelative.name))]),_c('div',{staticClass:"av-radio__role"},[_vm._v(_vm._s(_vm.selectedRelative.state))])])])])])]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }