<template>
  <v-form class="modal-service" v-model="valid">
      <div
        v-if="screen === 'main'"
        class="modal-service__main"
      >
        <div class="modal-service__title">
          <template v-if="mode === 'create'">Необходимая услуга</template>
          <template v-else>Редактировать</template>
        </div>
        <div class="modal-service__text">
          <template v-if="mode === 'create'">Внесите все необходимые данные, чтобы добавить услугу</template>
          <template v-else>Вы можете внести изменения</template>
        </div>

        <template v-if="place === 'account'">
          <ChooseRelative @input="selectRelative"></ChooseRelative>
        </template>

        <div class="field-ell--hidden">
          <Select
            label="Выберите категорию услуги"
            :items="categories"
            item-value="id"
            item-text="name"
            v-model="formData.category"
            required
          />
        </div>
        <div class="field-ell--hidden">
          <Select
            label="Выберите услугу"
            :disabled="!formData.category"
            item-value="id"
            item-text="name"
            :items="services"
            v-model="formData.responsibility"
            required
          />
        </div>
        <template v-if="mode === 'create'">
          <div class="field-ell--inline">
            <DatePicker
              label="Дата начала получения услуги"
              v-model="formData.start_date"
              :min-date="currentDate"
              :max-date="formData.end_date"
              required
            ></DatePicker>
          </div>
          <div class="field-ell--inline">
            <DatePicker
              label="Дата окончания получения услуги"
              v-model="formData.end_date"
              :min-date="formData.start_date"
              required
            ></DatePicker>
          </div>
        </template>

        <div class="field-ell--inline">
          <TextInput
            label="Количество часов в день"
            v-mask="['#', '##']"
            :rules="[minHoursRule]"
            v-model="formData.hours_per_day"
            required
          />
        </div>
        <div class="field-ell--inline">
          <TextInput
            label="В какое время"
            v-model="formData.time"
            :rules="[maxTimeRule, correctFromatRule]"
            v-mask="`##:##`"
            required
          />
        </div>
        <template v-if="mode === 'create'">
          <div class="modal-service__bl">
            <div class="modal-service__next" @click="screen = 'regularity'">
              <div class="modal-service__next-label">В какие дни</div>

              <div class="modal-service__next-val">
                <template v-if="formData.repeat.length">
                  <span>{{ selectedRepeat.join(', ') }}</span>
                </template>

                <template v-else>
                  <span>Нет</span>
                </template>

                <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M12.062.065l1.428 1.426-6.494 6.507L.49 1.49 1.917.064l5.078 5.078L12.062.065z" fill="#AEAEB2"/></svg>
              </div>
            </div>
          </div>
        </template>
        <div class="field-ell--hidden">
          <AddressAutocomplete
            label="Адрес места оказания услуги"
            v-model="formData.address"
          ></AddressAutocomplete>
        </div>
        <div class="field-ell--hidden">
          <TextInput
            :rules="[minPriceRule]"
            label="Указать сумму вознаграждения"
            v-model="formData.price"
            required
            :onlyNumbers="true"
          />
        </div>
        <button :disabled="loading || !valid" type="button" class="btn btn--prime" @click="submit">
          <v-progress-circular v-if="loading" indeterminate color="primary"></v-progress-circular>
          <template v-else>
            <template v-if="mode === 'create'">Добавить услугу</template>
            <template v-else>Сохранить</template>
          </template>
        </button>
      </div>
    <template v-else-if="screen === 'regularity'">
      <RegularitySection
        @back="screen = 'main'"
        v-model="formData.repeat"
      ></RegularitySection>
    </template>

    <template v-else-if="screen === 'success'">
      <div class="modal-service__success">
        <div class="modal-service__success-icon">
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M11 0C4.935 0 0 4.934 0 11c0 6.065 4.935 11 11 11s11-4.935 11-11c0-6.066-4.935-11-11-11zm6.316 9.142l-6.91 6.91a1.545 1.545 0 01-1.1.456c-.416 0-.806-.162-1.1-.455L4.684 12.53a1.546 1.546 0 01-.456-1.1c0-.416.162-.807.456-1.1a1.545 1.545 0 011.1-.456c.415 0 .806.162 1.1.456l2.422 2.422 5.81-5.811a1.545 1.545 0 011.1-.456 1.557 1.557 0 011.1 2.656z" fill="#0C76DA"/></svg>
        </div>

        <div class="modal-service__title">Заявка создана</div>

        <div class="modal-service__text">Находится в разделе<br />“Созданные заявки”</div>
      </div>
    </template>
  </v-form>
</template>

<script>
import Select from '@component/UI/Form/Select.vue';
import TextInput from '@component/UI/Form/TextInput';
import DatePicker from '@component/UI/Form/DatePicker';
import { mapActions, mapState, mapMutations } from 'vuex';
import AddressAutocomplete from '@component/UI/Form/AddressAutocomplete';
import RegularitySection from '@component/Module/ModalServiceNeed/ModalServiceNeedRegularity';
import ChooseRelative from '@component/Module/ModalServiceNeed/ModalServiceNeedChooseRelative';

export default {
  name: 'ModalServiceNeed',
  components: {
    AddressAutocomplete,
    Select,
    TextInput,
    DatePicker,
    RegularitySection,
    ChooseRelative,
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
    place: {
      // registration, account
      type: String,
      default: 'registration',
    },
    title: String,
    text: String,
  },

  watch: {
    'formData.start_date'(v) {
      if (!v) return;
      this.setDayOfWeek(v, this.formData.end_date);
    },
    'formData.end_date'(v) {
      if (!v) return;
      this.setDayOfWeek(v, this.formData.start_date);
    }
  },

  computed: {
    ...mapState({
      categories: state => state.enums.categories,
      repeats: state => state.enums.repeats,
      user: state => state.user.user,
    }),

    mode() {
      return Object.entries(this.data).length ? 'update' : 'create';
    },

    selectedRepeat() {
      return this.formData.repeat.map(key => {
        return this.repeats.find(repeat => key === repeat.key).value;
      });
    },

    responsibilityMinPrice() {
      const selectedResponsibility = this.services.find(
        responsibility => responsibility.id === this.formData.responsibility
      );
      if (selectedResponsibility && selectedResponsibility.price) {
        return selectedResponsibility.price;
      }
      return 0;
    },

    services() {
      const category = this.categories.find(category => category.id === this.formData.category);
      return category?.responsibility ?? [];
    },
  },
  data() {
    return {
      screen: 'main',
      valid: false,
      currentDate: '',
      loading: false,
      formData: {
        family: null, // <ID близкого>
        hours_per_day: null, // <время выполнения работы>
        price: null, // <стоимость работы>
        category: null, // <ID категории (мед.помощь)
        responsibility: null, // <Обьект обязанности (уколы)>
        address: {
          address: '', // <строка адреса>
          family: null, // <ID близкого>
          coordinates: {
            latitude: '',
            longitude: '',
          },
        },
        time: '', // <во сколько оказывать услугу в формате HH:MM:SS>,
        start_date: '', // <с какого времени генерить заявки в формате YYYY-MM-DD HH:MM>
        end_date: '', // <до времени генерить заявки в формате YYYY-MM-DD HH:MM>
        repeat: [], // <ключи дней недели, в которые повторять>
      },
    };
  },
  methods: {
    ...mapActions('user', ['createServiceRequest', 'updateServiceRequest']),
    ...mapMutations('user', ['setIsAddedServiceRequest']),
    setDayOfWeek(dateA, dateB) {
      if (dateA === dateB) {
        const { key } = this.repeats[DateTime(dateA, 'YYYY-MM-DD').weekday()];
        this.formData.repeat = [key];
      }
    },
    selectRelative(id) {
      this.formData.family = id;
      this.formData.address.family = id;
    },
    async submit() {
      this.loading = true;
      if (this.mode === 'create') {
        if (this.place === 'registration') {
          //TODO: Для тестирования. В дальнейшем подставлять ID родственника.
          const { id: familyId } = this.user.family_set.find(family => family.state === 'SLF');

          this.formData.family = familyId;
          this.formData.address.family = familyId;
        }

        await this.createServiceRequest(this.formData).finally(() => {
          this.loading = false;
        });
        await this.setIsAddedServiceRequest();
      } else {
        await this.updateServiceRequest(this.formData).finally(() => {
          this.loading = false;
        });
      }

      if (this.place === 'registration') {
        this.$emit('close');
      } else {
        this.screen = 'success';
      }
    },
    minPriceRule: function(value) {
      return value >= this.responsibilityMinPrice || `Минимальное значение ${this.responsibilityMinPrice} рублей`;
    },
    minHoursRule: function(value) {
      return value <= 24 || `Максимальное значение 24 часа`;
    },
    maxTimeRule: function(value) {
      if (value.length < 5) return true;
      let valueParts = value.split(':');
      console.log(+valueParts[0] * 60 + +valueParts[1], valueParts);
      if (+valueParts[1] > 59 || +valueParts[0] > 23) return `Введите корректное время`;
      if (+valueParts[0] * 60 + +valueParts[1] > 1439) return `Максимальное значение 23:59 часа`;
      return true;
      // return value <= 24 || `Максимальное значение 24 часа`;
    },
    correctFromatRule(value) {
      let valueParts = value.split(':');
      if (value.length === 5 && valueParts.length === 2) {
        return true;
      } else {
        return `Введите часы и минуты`;
      }
    },
    // submit() {
    //   this.$emit('submit', this.formData);
    // }
  },
  async mounted() {
    this.currentDate = DateTime().format('DD.MM.YYYY');

    await this.$store.dispatch('enums/getRepeats');
    await this.$store.dispatch('enums/getCategories');

    if (this.mode === 'update') {
      // TODO: Temporary solution, for testing
      const data = this.data;

      delete data.images;
      delete data.is_rate;
      delete data.distance;
      delete data.accepted_response;

      //
      this.formData = Object.assign(
        {},
        {
          ...data,
          category: data.category.id,
          family: data.family.id,
          responsibility: data.responsibility.id,
        }
      );
    }
  },
};
</script>

<style lang="scss" scoped>
.modal-service {

  &__main {
    padding: 45px 26px 34px 26px;
  }

  &__success {
    padding: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &-icon {
      width: 74px;
      height: 74px;
      border-radius: 50%;
      border: 1px solid var(--color-prime);
      position: relative;
      margin-bottom: 22px;
      svg,
      &::before {
        content: '';
        position: absolute;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        //background-color: var(--color-prime);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
::v-deep {
  .v-input {
    .v-select__selections {
      font-size: 19px;
    }
  }
}
</style>
